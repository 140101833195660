<template>
  <dx-load-panel v-model:visible="visible" :hideOnOutsideClick="false" :shading="true"
    shadingColor="#00000080"></dx-load-panel>
</template>

<script>
import { DxLoadPanel } from 'devextreme-vue/load-panel';

export default {
  components: {
    DxLoadPanel
  },
  computed: {
    visible: {
      get() {
        return this.busy;
      },
      set(value) {
        this.emit('update:visible', value);
      }
    }
  },
  props: {
    busy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  }
}
</script>