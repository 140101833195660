<template>
<slot></slot>
</template>

<script>
export default {
  components: {
  },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
</style>
