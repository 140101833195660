<template>
  <div :class="['text-2xl mb-3', { 'text-center': isCenter }]">{{ text }}</div>
</template>

<script>
export default {
  props: {
    isCenter: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: 'My Title'
    }
  }
}
</script>