import axios from "axios";
import { defineStore } from "pinia";

export const useAppStore = defineStore("app", {
  state: () => ({
    user: null,
    busy: false,
    server: "http://localhost:8000/",
    screenSizeInfo: {},
  }),
  getters: {
    isAdminUser() {
      return this.user.is_admin;
    },
    daftarAkses() {
      return this.user?.daftar_akses || [];
    },
    isLogin() {
      return this.user != null;
    },
    baseFileUrl() {
      return this.server + "file/"
    },
    apiUrl() {
      return this.server + "api/"
    },
  },
  actions: {
    loadAkses() {
      if (!this.user) {
        this.daftarAkses = [];
      }
    },
    boleh(s) {
      console.info('boleh', s);
      if (!this.user) {
        return false;
      }
      if (this.isAdminUser) {
        return true;
      }
      let temp = s.split(":");
      let kiri = temp[0];
      let kanan = temp[1];
      let akses = this.daftarAkses.find(x => x.key == kiri);
      return akses && akses[kanan];
    },
    getDefaultImageUrl() {
      return this.getFileUrl("default/foto.jpeg")
    },
    getFileUrl(file_path) {
      if (file_path) {
        return this.baseFileUrl + file_path
      }
      return null
    },
    login(user_name, password, remember_me) {
      return new Promise((resolve, reject) => {
        this.busy = true
        let u
        try {
          this.post('login', { user_name, password, remember_me })
            .then(u => {
              localStorage.setItem('user', JSON.stringify(u));
              this.user = u;
              resolve(u)
            })
            .catch(e => reject(e))
        } catch (error) {
          console.error(error)
          reject(error)
        } finally {
          this.busy = false
        }
        return u;
      })
    },
    logout() {
      this.busy = true;
      return new Promise((resolve, reject) => {
        localStorage.removeItem('user');
        this.user = null;
        this.post('logout', {})
          .catch(e => reject(e))
          .then(() => {
            this.busy = false;
            resolve();
          });
      })
    },
    loadServerUrl() {
      this.busy = true;
      return axios.get("/config.json")
        .then((response) => {
          let config = response.data;
          this.server = config.server;
        })
        .catch(e => console.error(e))
        .then(() => this.busy = false);

    },
    restoreLogin() {
      this.busy = true;
      try {
        let s = localStorage.getItem("user");
        if (s) {
          try {
            let u = JSON.parse(s);
            this.user = u;
            return u;
          } catch (error) {
            console.error(error);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.busy = false;
      }
      return null;
    },
    async post(url, data) {
      let token = this.user?.token || '';
      try {
        let res = await axios.post(this.apiUrl + url, data, {
          headers: {
            token
          }
        });
        let api = res.data
        if (!api.ok) {
          try {
            throw new Error(api.data.join('<br/>'));
          } catch (er) {
            throw new Error(api.msg)
          }
        }
        return api.data
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    postFile(url, data) {
      let token = this.user?.token || '';
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.apiUrl}${url}`, data, {
            headers: {
              "Content-Type": "multipart/form-data",
              token,
            }
          })
          .then((res) => {
            let api = res.data;
            if (!api.ok) {
              reject(api.msg);
            } else {
              resolve(api.data);
            }
          })
          .catch((e) => reject(e));
      });
    },
    async changePassword(email, recoveryCode) {
      try {
        // Send request
        console.log(email, recoveryCode);

        return {
          isOk: true
        };
      }
      catch {
        return {
          isOk: false,
          message: "Failed to change password"
        }
      }
    },

    async createAccount(nama, user_name, password) {
      try {
        // Send request
        await this.post('register', { nama, user_name, password });
        // this._user = { ...defaultUser, user_name: user_name };
        // ApiHelper.token = this._user.token;
        return {
          isOk: true
        };
      }
      catch (e) {
        console.error(e)
        return {
          isOk: false,
          message: e.message
        };
      }
    },
  }
});