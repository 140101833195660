<template>
  <dx-button @click="$emit('click')" :icon="icon" :text="text" :type="type"></dx-button>
</template>

<script>
import DxButton from 'devextreme-vue/button';
export default {
  components: {
    DxButton
  },
  props: {
    type: {
      type: String,
      default: 'outlined'
    },
    text: {
      type: String,
      default: 'Simpan'
    },
    icon: {
      type: String,
      default: 'fa-solid fa-floppy-disk'
    }
  },
  emits: ['click'],
}
</script>