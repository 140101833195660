<template>
  <div :class="[swatchClassName, 'side-navigation-menu']" @click="forwardClick">
    <slot />
    <div class="menu-container">
      <dx-tree-view
        ref="treeViewRef"
        :items="items"
        key-expr="path"
        selection-mode="single"
        :focus-state-enabled="false"
        expand-event="click"
        @item-click="handleItemClick"
        width="100%"
      />
    </div>
  </div>
</template>

<script>
import DxTreeView from "devextreme-vue/tree-view";
import { sizes } from "../utils/media-query";
import navigation from "../app-navigation";
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { themeService } from "../theme-service";
import { useAppStore } from "@/stores/app-store";

export default {
  props: {
    compactMode: Boolean,
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const store = useAppStore();
    const daftarAkses = (store.user?.daftar_akses || []).filter(x=>x.lihat == 1);
    const isLargeScreen = sizes()["screen-large"];
    console.dir(daftarAkses);
    const temp_items = navigation
      .map((item) => {
        if (item.path && !/^\//.test(item.path)) {
          item.path = `/${item.path}`;
        }
        return { ...item, expanded: isLargeScreen };
      });
    const treeViewRef = ref(null);
    const swatchClassName = ref("");

    const items = [];
    for(var item of temp_items) {
      let t = {...item};
      let valid = false;
      item.items = item.items || [];
      if(item.items.length > 0) {
        t.items = [];
        for(var subitem of item.items) {
          if(store.boleh(subitem.path.replace(/^\//, '') + ':lihat')) {
            t.items.push(subitem);
            valid = true;
          }
        }
      } else {
        if(item.path == '/') {
          valid = true;
        } else {
          valid = store.boleh(subitem.path.replace(/^\//, '') + ':lihat');
        }
      }
      if(valid) {
        items.push(t);
      }
    }

    function forwardClick(...args) {
      context.emit("click", args);
    }

    function handleItemClick(e) {
      if (!e.itemData.path || props.compactMode) {
        return;
      }
      router.push(e.itemData.path);

      const pointerEvent = e.event;
      pointerEvent.stopPropagation();
    }

    function updateSelection() {
      if (!treeViewRef.value || !treeViewRef.value.instance) {
        return;
      }

      treeViewRef.value.instance.selectItem(route.path);
      treeViewRef.value.instance.expandItem(route.path);
    }

    onMounted(() => {
      updateSelection();
      if (props.compactMode) {
        treeViewRef.value.instance.collapseAll();
      }
    });

    watch(
      () => route.path,
      () => {
        updateSelection();
      }
    );

    watch(
      () => themeService.isDark,
      () => {
        swatchClassName.value = themeService.isDark.value
          ? "dx-swatch-additional-dark"
          : "dx-swatch-additional";
      },
      { immediate: true }
    );

    watch(
      () => props.compactMode,
      () => {
        if (props.compactMode) {
          treeViewRef.value.instance.collapseAll();
        } else {
          updateSelection();
        }
      }
    );

    return {
      treeViewRef,
      items,
      forwardClick,
      handleItemClick,
      updateSelection,
      swatchClassName,
    };
  },
  components: {
    DxTreeView,
  },
};
</script>

<style lang="scss">
@import "../variables.scss";
@import "../dx-styles.scss";

.dx-swatch-additional,
.dx-swatch-additional-dark {
  &.side-navigation-menu {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    width: 250px !important;
    background-color: var(--base-bg);

    .menu-container {
      min-height: 100%;
      display: flex;
      flex: 1;

      .dx-treeview {
        // ## Long text positioning
        white-space: nowrap;
        // ##

        .dx-treeview-node-container:empty {
          display: none;
        }

        // ## Icon width customization
        .dx-treeview-item {
          padding-left: 0;
          border-radius: 0;
          flex-direction: row-reverse;

          .dx-icon {
            width: $side-panel-min-width !important;
            margin: 0 !important;
          }
        }

        // ##

        // ## Arrow customization
        .dx-treeview-node {
          padding: 0 0 !important;
        }

        .dx-treeview-toggle-item-visibility {
          right: 10px;
          left: auto;
        }

        .dx-rtl .dx-treeview-toggle-item-visibility {
          left: 10px;
          right: auto;
        }
        // ##

        // ## Item levels customization
        .dx-treeview-node {
          &[aria-level="1"] {
            font-weight: bold;
          }

          &[aria-level="2"] .dx-treeview-item-content {
            font-weight: normal;
            padding: 0 $side-panel-min-width;
          }
        }
        // ##
      }
    }
  }
}
</style>
