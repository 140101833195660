<template>
  <div>
    <dx-data-grid ref="grid" :dataSource="dataSource" :allow-column-reordering="true" @exporting="gridExporting"
      :column-min-width="120" column-resizing-mode="widget" :allow-column-resizing="true" :column-auto-width="true" height="520">
      <dx-remote-operations v-if="remoteOperations" :group-paging="false" :grouping="false"></dx-remote-operations>
      <dx-header-filter :visible="true">
        <dx-search :enabled="true" mode="contains"></dx-search>
      </dx-header-filter>
      <dx-grouping :context-menu-enabled="true"></dx-grouping>
      <dx-group-panel :visible="true" text="Group Panel"></dx-group-panel>
      <dx-search-panel :visible="true" width="240"></dx-search-panel>
      <dx-scrolling :scroll-by-content="true"></dx-scrolling>
      <dx-filter-panel :visible="true"></dx-filter-panel>
      <dx-editing :allow-adding="bolehBaru" :allow-updating="bolehEdit" :allow-deleting="bolehHapus" :use-icons="true"></dx-editing>
      <dx-export :enabled="bolehExport" :formats="gridExportFormats"></dx-export>
      <dx-filter-row :visible="true"></dx-filter-row>
      <dx-column-chooser :enabled="true"></dx-column-chooser>
      <dx-state-storing :enabled="true" :storage-key="storageKey"></dx-state-storing>
      <dx-toolbar>
        <dx-item name="groupPanel"></dx-item>
        <dx-item name="searchPanel" locate-in-menu="auto"></dx-item>
        <dx-item v-if="bolehExport" name="exportButton"></dx-item>
        <dx-item name="columnChooserButton"></dx-item>
        <dx-item location="after" widget="dxButton" :options="{ icon: 'refresh', text: 'Refresh', onClick: onRefresh }" locate-in-menu="auto">
        </dx-item>
        <dx-item v-if="bolehBaru" location="after" widget="dxButton"
          :options="{ icon: 'add', text: 'Baru', onClick: onBaru }" locate-in-menu="auto"></dx-item>
      </dx-toolbar>
      <dx-column v-if="!isMobile" type="buttons" :fixed="false" fixedPosition="left">
        <dx-button v-if="bolehEdit" icon="edit" @click="(e)=>onEdit(e.row.data)"></dx-button>
        <dx-button v-if="bolehHapus" icon="trash" @click="(e)=>onHapus(e.row.data)"></dx-button>
        <dx-button v-if="bolehPrint" icon="print" @click="(e)=>onPrint(e.row.data)"></dx-button>
      </dx-column>

      <dx-column v-if="isMobile" type="buttons" :fixed="false" fixedPosition="left">
        <dx-button name="mobileButtons" template="buttonTemplate">
        </dx-button>
      </dx-column>
      <dx-column v-for="(item, index) in columns" :key="index" :data-field="item.dataField" :caption="item.caption"
      :data-type="item.dataType" :format="getColumnFormat(item)"></dx-column>
      <dx-summary>
        <dx-total-item v-for="(item, index) in totalItems" :key="index" :summary-type="item.summaryType"
        :column="item.column" display-format="{0}" :value-format="item.valueFormat"></dx-total-item>
      </dx-summary>
      <template #buttonTemplate="{data}">
        <dx-drop-down-button icon="fas fa-edit" :items="mobileMenuItems" @item-click="({itemData})=>onButtonClick(data.row.data, itemData)" :width="120"></dx-drop-down-button>
      </template>
    </dx-data-grid>
  </div>
</template>

<script>

import GridHelper from '@/helper/grid-helper';
import { useAppStore } from '@/stores/app-store';
import { DxDataGrid, DxSearch, DxToolbar, DxItem, DxButton, DxColumn, DxSummary, DxTotalItem, DxColumnChooser, DxStateStoring, DxFilterRow, DxExport, DxEditing, DxScrolling, DxFilterPanel, DxGroupPanel, DxGrouping, DxSearchPanel, DxHeaderFilter, DxRemoteOperations } from 'devextreme-vue/data-grid';
import { DxDropDownButton } from 'devextreme-vue/drop-down-button';

export default {
  components: {
    DxDataGrid, DxSearch, DxToolbar, DxItem, DxButton, DxColumn, DxSummary, DxTotalItem, DxColumnChooser, DxStateStoring, DxFilterRow, DxExport, DxEditing, DxScrolling,
    DxFilterPanel, DxGroupPanel, DxGrouping, DxSearchPanel, DxHeaderFilter, DxRemoteOperations,
    DxDropDownButton
  },
  methods: {
    onButtonClick(data, itemData) {
        switch(itemData.text) {
        case 'Edit':
          this.onEdit(data);
          break;
        case 'Hapus':
          this.onHapus(data);
          break;
        case 'Print':
          this.onPrint(data);
          break;
      }
    },
    onBaru() {
      this.$emit('baru');
    },
    onEdit(data) {
      this.$emit('edit', data);
    },
    onHapus(data) {
      this.$emit('hapus', data);
    },
    onPrint(data) {
      this.$emit('print', data);
    },
    onRefresh() {
      this.$emit('refresh');
    },
    gridExporting: GridHelper.onExporting,
    getColumnFormat(col) {
      switch (col.dataType) {
        case 'date':
          return 'dd-MM-yyyy HH:mm:ss';
        case 'number':
          return '#,##0.##;(#,##0.##)';
        default:
          return '';
      }
    }
  },
  props: {
    totalItems: {
      type: Array,
      default: () => []
    },
    remoteOperations: {
      type: Boolean,
      default: false
    },
    storageKey: {
      type: String,
      default: 'grid'
    },
    gridExportFormats: {
      type: Array,
      default: () => ['xlsx', 'csv', 'pdf']
    },
    bolehBaru: {
      type: Boolean,
      default: true
    },
    bolehEdit: {
      type: Boolean,
      default: true
    },
    bolehHapus: {
      type: Boolean,
      default: true
    },
    bolehExport: {
      type: Boolean,
      default: true
    },
    bolehPrint: {
      type: Boolean,
      default: false
    },
    daftar: {
      type: Object || Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  emits: ['baru', 'edit', 'hapus', 'print', 'refresh'],
  computed: {
    mobileMenuItems() {
      let daf = [];
      if(this.bolehEdit) {
        daf.push({ text: 'Edit', icon: 'edit', onClick: this.onEdit });
      }
      if(this.bolehHapus) {
        daf.push({ text: 'Delete', icon: 'trash', onClick: this.onHapus });
      }
      if(this.bolehPrint) {
        daf.push({ text: 'Print', icon: 'print', onClick: this.onPrint });
      }
      return daf;
    },
    instance() {
      return this.$refs.grid.instance;
    },
    isMobile() {
      const store = useAppStore();
      return store.screenSizeInfo.isXSmall;
    },
    dataSource: {
      get() {
        return this.daftar;
      },
      set(value) {
        this.$emit('update:daftar', value);
      }
    }
  },
  mounted() {
  },
  data() {
    return {
      
    }
  }
}
</script>