<template>
  <dx-popup v-model:visible="visibleComputed" :full-screen="fullScreen" :show-title="true" :show-close-button="true"
        :hide-on-outside-click="false" content-template="popup-content" title-template="title-template">
        <dx-toolbar-item toolbar="bottom" location="after" widget="dxButton" :options="simpanOptions"></dx-toolbar-item>
        <dx-toolbar-item toolbar="bottom" location="after" widget="dxButton" :options="batalOptions"></dx-toolbar-item>
        <template #title-template>
          <div class="ml-1">
            <my-title :text="title"></my-title>
          </div>
        </template>
        <template #popup-content>
          <dx-scroll-view height="100%" width="100%">
            <slot></slot>
          </dx-scroll-view>
        </template>
      </dx-popup>
</template>

<script>
import { useAppStore } from '@/stores/app-store';
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import {DxScrollView} from 'devextreme-vue/scroll-view';
export default {
  components: {
    DxPopup, DxToolbarItem, DxScrollView
  },
  computed: {
    fullScreen() {
      const store = useAppStore();
      return store.screenSizeInfo.isXSmall;
    },
    visibleComputed: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('update:visible', value);
      }
    }
  },
  emits: ['update:visible', 'simpan'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Dialog'
    },
  },
  data() {
    return {
      batalOptions: {
        elementAttr: { class: 'mr-3' },
        text: 'Batal',
        icon: 'fas fa-times',
        onClick: () => {
          this.$emit('update:visible', false);
        }
      },
      simpanOptions: {
        text: 'Simpan',
        icon: 'fas fa-save',
        onClick: () => {
          this.$emit('simpan');
        }
      }
    }
  }
}
</script>