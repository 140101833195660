import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.dark.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.dark.css';
import './themes/generated/theme.additional.css';
import './styles.css';
import { createApp } from "vue";
import router from "./router";
import themes from "devextreme/ui/themes";

import App from "./App";
import appInfo from "./app-info";

import MyTitle from './components/my-title.vue';
import MyGrid from './components/my-grid.vue';

import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';

import { createPinia } from 'pinia';
import MyForm from './components/my-form.vue';
import MySpacer from './components/my-spacer.vue';
import MyButton from './components/my-button.vue';
import MyEntriGrid from './components/my-entri-grid.vue';
import MyDialog from './components/my-dialog.vue';

themes.initialized(() => {
    config({ licenseKey });
    const app = createApp(App);
    const store = createPinia();
    app.use(store);

    app.component('MyTitle', MyTitle);
    app.component('MyGrid', MyGrid);
    app.component('MyEntriGrid', MyEntriGrid);
    app.component('MyForm', MyForm);
    app.component('MySpacer', MySpacer);
    app.component('MyButton', MyButton);
    app.component('MyDialog', MyDialog);

    app.use(router);
    app.config.globalProperties.$appInfo = appInfo;
    app.mount('#app');
});
