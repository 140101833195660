<template>
  <div>
    <dx-data-grid
      ref="grid"
      v-model:data-source="dataSourceComputed"
      :allow-column-reordering="true"
      @exporting="gridExporting"
      :column-min-width="120"
      column-resizing-mode="widget"
      :allow-column-resizing="true"
      :column-auto-width="true"
    >
      <dx-header-filter :visible="allowFilter">
        <dx-search :enabled="allowFilter" mode="contains"></dx-search>
      </dx-header-filter>
      <dx-selection></dx-selection>
      <dx-grouping :context-menu-enabled="false"></dx-grouping>
      <dx-group-panel :visible="false"></dx-group-panel>
      <dx-search-panel :visible="allowFilter" width="240"></dx-search-panel>
      <dx-scrolling :scroll-by-content="true"></dx-scrolling>
      <dx-filter-panel :visible="allowFilter"></dx-filter-panel>
      <dx-editing
        :mode="editingMode"
        :allow-adding="allowAdding"
        :allow-updating="allowUpdating"
        :allow-deleting="allowDeleting"
        :use-icons="true"
      ></dx-editing>
      <dx-export :enabled="false" :formats="gridExportFormats"></dx-export>
      <dx-filter-row :visible="allowFilter"></dx-filter-row>
      <dx-column-chooser :enabled="true"></dx-column-chooser>
      <dx-state-storing
        :enabled="true"
        :storage-key="storageKey"
      ></dx-state-storing>
      <dx-selection
        v-if="allowSelection"
        mode="multiple"
        :allow-select-all="true"
        select-all-mode="allPages"
      ></dx-selection>
      <dx-toolbar>
        <dx-item name="searchPanel"></dx-item>
        <dx-item name="exportButton"></dx-item>
        <dx-item name="columnChooserButton"></dx-item>
        <dx-item
          v-for="(item, index) in customToolbars"
          :key="index"
          :location="item.location"
          :widget="item.widget"
          :options="item.options"
        >
        </dx-item>
        <dx-item name="addButton"></dx-item>
      </dx-toolbar>
      <dx-column
        v-for="(item, index) in columns"
        :key="index"
        :data-field="item.dataField"
        :caption="item.caption"
        :data-type="item.dataType"
        :format="getColumnFormat(item)"
        :allow-editing="item.allowEditing"
        :fixed="!!item.fixed"
        :fixed-position="item.fixedPosition || 'left'"
      ></dx-column>
      <dx-column
        v-if="
          bolehBaru ||
          (bolehUpdate && editingMode == 'row') ||
          bolehHapus ||
          customRowButtons.length > 0
        "
        type="buttons"
      >
        <dx-button name="edit"></dx-button>
        <dx-button name="delete"></dx-button>
        <dx-button name="save"></dx-button>
        <dx-button name="cancel"></dx-button>
        <dx-button
          v-for="(item, index) in customRowButtons"
          :key="index"
          :name="item.name"
          :icon="item.icon"
          @click="item.onClick"
        ></dx-button>
      </dx-column>
      <dx-summary>
        <dx-total-item
          v-for="(item, index) in totalItems"
          :key="index"
          :summary-type="item.summaryType"
          :column="item.column"
          display-format="{0}"
          :value-format="item.valueFormat"
        ></dx-total-item>
      </dx-summary>
    </dx-data-grid>
  </div>
</template>

<script>
import GridHelper from "@/helper/grid-helper";
import {
  DxDataGrid,
  DxSearch,
  DxColumn,
  DxSummary,
  DxButton,
  DxTotalItem,
  DxColumnChooser,
  DxStateStoring,
  DxFilterRow,
  DxSelection,
  DxExport,
  DxEditing,
  DxScrolling,
  DxFilterPanel,
  DxGroupPanel,
  DxGrouping,
  DxSearchPanel,
  DxHeaderFilter,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";

export default {
  components: {
    DxDataGrid,
    DxSearch,
    DxColumn,
    DxSummary,
    DxButton,
    DxTotalItem,
    DxColumnChooser,
    DxStateStoring,
    DxFilterRow,
    DxSelection,
    DxExport,
    DxEditing,
    DxScrolling,
    DxFilterPanel,
    DxGroupPanel,
    DxGrouping,
    DxSearchPanel,
    DxHeaderFilter,
    DxToolbar,
    DxItem,
  },
  methods: {
    gridExporting: GridHelper.onExporting,
    getColumnFormat(col) {
      switch (col.dataType) {
        case "date":
          return "dd-MM-yyyy HH:mm:ss";
        case "number":
          return "#,##0.##;(#,##0.##)";
        default:
          return "";
      }
    },
  },
  props: {
    bolehBaru: {
      type: Boolean,
      default: true,
    },
    bolehUpdate: {
      type: Boolean,
      default: true,
    },
    bolehHapus: {
      type: Boolean,
      default: true,
    },
    allowSelection: {
      type: Boolean,
      default: false,
    },
    allowAdding: {
      type: Boolean,
      default: true,
    },
    allowUpdating: {
      type: Boolean,
      default: true,
    },
    allowDeleting: {
      type: Boolean,
      default: true,
    },
    customRowButtons: {
      type: Array,
      default: () => [],
    },
    customToolbars: {
      type: Array,
      default: () => [],
    },
    editingMode: {
      type: String,
      default: "row",
    },
    allowFilter: {
      type: Boolean,
      default: false,
    },
    totalItems: {
      type: Array,
      default: () => [],
    },
    storageKey: {
      type: String,
      default: "entri-grid",
    },
    gridExportFormats: {
      type: Array,
      default: () => ["xlsx", "csv", "pdf"],
    },
    daftar: {
      type: Object || Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    dataSourceComputed: {
      get() {
        return this.daftar;
      },
      set(value) {
        this.$emit("update:daftar", value);
      },
    },
    instance() {
      return this.$refs.grid.instance;
    },
    dataSource: {
      get() {
        return this.daftar;
      },
      set(value) {
        this.$emit("update:daftar", value);
      },
    },
  },
};
</script>
