function createNavigation(text, path, icon, items) {
  return {
    text,
    path,
    icon,
    items
  }
}

export default [
  createNavigation("Home", "/", "fas fa-home", []),
  createNavigation("Master", null, 'fas fa-cube', [
    createNavigation("Akses", "/akses", null, []),
    createNavigation("Pengguna", "/pengguna", null, []),
    createNavigation("Karyawan", "/karyawan", null, []),
    createNavigation("Akun Karyawan", "/akun_karyawan", null, []),
    createNavigation("Jabatan", "/jabatan", null, []),
    createNavigation("Jenis Absen", "/jenis_absen", null, []),
    createNavigation("Departemen", "/departemen", null, []),
  ]),
  createNavigation("Absensi", null, 'fas fa-qrcode', [
    createNavigation("Generate QR", "/generate_qr", null, []),
    createNavigation("Scan QR", "/scan_qr", null, []),
    createNavigation("Token Absen", "/token_absen", null, []),
  ]),
  createNavigation("Pengaturan", "/pengaturan", 'fas fa-cog', []),
  createNavigation("Laporan", null, 'fas fa-file-alt', [
    createNavigation("Absensi", "/laporan/absen", null, []),
    createNavigation("Karyawan", "/laporan_karyawan", null, []),
  ]),
  {
    text: "Examples",
    icon: "folder",
    items: [
      {
        text: "Profile",
        path: "/profile"
      },
      {
        text: "Tasks",
        path: "/tasks"
      }
    ]
  },
];
