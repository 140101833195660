import { createRouter, createWebHashHistory } from "vue-router";

// import Home from "./views/home-page";
import Profile from "./views/profile-page";
import Tasks from "./views/tasks-page";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";
import { useAppStore } from "./stores/app-store";
import EmptyLayout from "./layouts/empty-layout.vue";

function loadView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

function loadPage(view, folder = "") {
  if (folder.length > 0) {
    folder = folder + "/";
  }
  return () => import(`./views/${folder}${view}-page.vue`)
}

function createRoute(name, requiresAuth = true, layout = defaultLayout) {
  return {
    name: name,
    path: `/${name}`,
    meta: {
      requiresAuth,
      layout
    },
    component: loadPage(name)
  }
}

function createLaporanRoute(name) {
  return {
    name: name,
    path: `/laporan/${name}`,
    meta: {
      requiresAuth: true,
      layout: defaultLayout
    },
    component: loadPage(name, "laporan")
  }
}

const router = new createRouter({
  routes: [
    createRoute("home"),
    createRoute("pengguna"),
    createRoute("akses"),
    createRoute("departemen"),
    createRoute("jabatan"),
    createRoute("jenis_absen"),
    createRoute("karyawan"),
    createRoute("generate_qr"),
    createRoute("scan_qr"),
    createRoute("token_absen"),
    createRoute("pengaturan"),
    createRoute("register_karyawan", false, EmptyLayout),
    createLaporanRoute("absen"),
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/tasks",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Tasks
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("login-form")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    }
  ],
  history: createWebHashHistory()
});


router.beforeEach((to, from, next) => {
  const store = useAppStore();
  let isLogin = store.isLogin;
  if (to.name === "login-form" && isLogin) {
    next({ name: "home" });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLogin) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else {
      console.info('to.path', to.path);
      if(to.path == '/' || to.name == 'home') {
        return next();
      }
      // check if user has access to the route
      let kiri = to.path.replace(/^\//, '');
      let boleh = store.boleh(kiri + ':lihat')
      console.info('boleh', boleh);
      if(boleh) {
        next();
      } else {
        next({
          name: "login-form",
          query: { redirect: to.fullPath }
        });
      }
    }
  } else {
    next();
  }
});

export default router;
